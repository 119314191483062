import { get, put } from '@/services/http';

export class BugModel {
  public id: number;
  public status: string;
  public code: string;
  public traceback: string;
  public issueLink: string;
  public relatedIds: number[];
  public bugType: string;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.status = data.status;
    this.code = data.code;
    this.traceback = data.traceback;
    this.issueLink = data.issueLink;
    this.relatedIds = data.relatedIds;
    this.bugType = data.bugType;
  }

  public async fetch() {
    const res: any = await get(`/bugs/${this.id}`);
    this.mapData(res.data);
  }

  public async update(status: string, cancelRelatedJobs: boolean = false) {
    const res: any = await put(`/bugs/${this.id}`, {
      status: status,
      cancel_related_jobs: cancelRelatedJobs,
    });
    this.status = status;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.status = data.status;
    this.code = data.code;
    this.traceback = data.traceback;
    this.issueLink = data.issueLink;
    this.relatedIds = data.relatedIds;
    this.bugType = data.bugType;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }
}
